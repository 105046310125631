<template>
  <div id="accountRecords">
    <header-nav :content="`${$t('pages_router_014')} / ${ $t('pages_router_020')}`" />
    <div class="common-card-body">
      <a-tabs
        v-model:activeKey="activeKey"
        class="header-tabs"
        @change="tabChange"
      >
        <template #rightExtra>
          <a-range-picker
            v-model:value="dateOpts"
            picker="month"
            format="YYYY/MM"
            value-format="YYYY-MM"
            @change="rangeChange"
          />
        </template>
        <a-tab-pane
          v-if="getUsedCheck(pageLabs, 160301)"
          key="month"
          :tab="$t('pages_account_045')"
        >
          <month-records ref="monthRef" />
        </a-tab-pane>
        <a-tab-pane
          v-if="getUsedCheck(pageLabs, 160302)"
          key="fee"
          :tab="$t('pages_account_046')"
        >
          <fee-records ref="feeRef" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs } from 'vue'
import HeaderNav from '@/components/header-nav'
import MonthRecords from './components/month-records'
import FeeRecords from './components/fee-records'
import { _account } from '@/api'
import { getUsedCheck } from '@/util'
export default {
  name: 'AccountRecords',
  components: {
    'header-nav': HeaderNav,
    'month-records': MonthRecords,
    'fee-records': FeeRecords
  },
  setup() {
    const state = reactive({
      activeKey: 'month',
      dateOpts: [],
      pageLabs: []
    })

    const monthRef = ref(null)
    const feeRef = ref(null)

    const tabChange = () => {
      state.dateOpts = []
      rangeChange('')
    }

    const rangeChange = (e) => {
      if (state.activeKey === 'month' && monthRef.value) monthRef.value.loadRecords(e)
      if (state.activeKey === 'fee' && feeRef.value) feeRef.value.loadRecords(e)
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1603 })
      if (res.data.succ) {
        state.pageLabs = res.data.data || []
      }
    }

    loadChildrenMenu()

    return {
      monthRef,
      feeRef,
      getUsedCheck,
      tabChange,
      rangeChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#accountRecords {
  .common-card-body {
    padding: 8px 0;
    .header-tabs:deep(.ant-tabs-nav) {
      padding: 0 24px;
    }
  }
}
</style>
