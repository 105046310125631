<template>
  <div id="feeRecords">
    <div class="table-part">
      <a-table
        :scroll="{ x: 800 }"
        :loading="loading"
        :pagination="false"
        :custom-header-row="() => { return {class: 'my-table-tr'}}"
        :columns="columns"
        :data-source="records"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'edit'">
            <a-button
              size="small"
              type="link"
              @click="downXls(record)"
            >{{ $t('common_text_019') }}</a-button>
          </template>
        </template>
      </a-table>
      <table-page
        :total="total"
        :page-opts="pageOpts"
        @update-page="updatePage"
      />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs } from 'vue'
import { _account } from '@/api'
import TablePage from '@/components/table-page'
import i18n from '@/locale'
import { getTradeStatusName } from '@/util'
export default {
  name: 'FeeRecords',
  components: {
    'table-page': TablePage
  },
  setup () {
    const state = reactive({
      loading: false,
      pageOpts: {
        pageNum: 1,
        pageSize: 10
      },
      total: 0,
      records: [],
      columns: [
        { key: 'month', dataIndex: 'month', title: i18n.global.t('pages_account_047'), width: 200 },
        { key: 'fileName', dataIndex: 'fileName', title: i18n.global.t('pages_account_048'), width: 280, ellipsis: true },
        { key: 'createTime', dataIndex: 'createTime', title: i18n.global.t('pages_account_049'), width: 200 },
        { key: 'payFlag', dataIndex: 'payFlagText', title: i18n.global.t('pages_account_050'), width: 150 },
        { key: 'edit', title: i18n.global.t('common_text_001'), fixed: 'right', width: 150, align: 'center' }
      ]
    })

    const downXls = (record) => {
      _account.downRecords({
        id: record.id,
        fileType: 'xls'
      })
    }

    const updatePage = (page, pageSize) => {
      state.pageOpts.pageNum = page
      state.pageOpts.pageSize = pageSize
      loadRecords()
    }

    const loadRecords = async (dateOpts) => {
      state.loading = true
      const query = Object.assign({}, state.pageOpts, {
        type: 'F',
        startMonth: dateOpts ? dateOpts[0] : '',
        endMonth: dateOpts ? dateOpts[1] : ''
      })

      const res = await _account.getRecords(query)
      if (res.data.succ) {
        state.records = res.data.data.list.map(item => {
          return Object.assign({}, item, {
            payFlagText: getTradeStatusName(item.payFlag, 'acctPayFlag')
          })
        })
        state.total = res.data.data.total
      }
      state.loading = false
    }

    loadRecords()

    return {
      downXls,
      updatePage,
      loadRecords,
      ...toRefs(state)
    }
  }
}
</script>

